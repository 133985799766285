.device-selection-container {
  text-align: center;
  margin: 50px auto;

  .main-header {
    margin-bottom: 40px;
    color: #888;
  }

  .devices-list {
    .device {
      display: inline-block;
      height: 252px;
      width: 230px;
      background-color: #FFFFFF;
      box-shadow: 0 2px 6px 0 rgba(0,0,0,0.19);
      margin: 8px;

      .icons {
        display: inline-block;
        width: 100%;
        height: 169px;
        padding-top: 30px;
        box-sizing: border-box;

        img {
          max-height: 45px;
          width: auto;
          margin-bottom:15px;
        }

        .icon {
          i {
            color: #0F949F;
            font-size: 55px;
            opacity: 0.6;
          }
        }

        .text {
          color: #0F949F;
          font-size: 24px;
          line-height: 29px;
          text-align: center;
        }

        .subtext {
          color: #888;
          font-size: 14px;
          line-height: 17px;
          text-align: center;
          padding: 0 20px;
        }
      }
      .device-path {
        display: inline-block;
        height: 83px;
        line-height: 75px;
        width: 100%;
        background-color: #f2f2f2;
        box-shadow: inset 0 1px 1px 0 rgba(0,0,0,0.09);
        font-size: 16px;
        text-align: center;
        padding: 5px 20px;
        box-sizing: border-box;

        .open-now {
          border-radius: 30px;
          font-size: 18px;
          width: 150px;
        }

        span {
          display: inline-block;
          vertical-align: middle;
          line-height: 20px;

          a {
            img {
              width: 150px;
              height: auto;
            }
          }
        }
      }
    }
  }

}